
import BaseCharts from '@/views/stats/base-charts.vue'

const categoryOps: { id: number; name: string }[] = [
  { id: 8, name: '总用户' },
  { id: 2, name: '活跃' },
  { id: 1, name: '新增' },
  { id: 3, name: '老用户' },
  { id: 4, name: '回流用户' },
  { id: 5, name: '未注册' }
]

export default {
  name: 'StatPlat',
  components: { BaseCharts },
  setup() {
    return {
      categoryOps
    }
  }
}
